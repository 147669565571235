import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { BrandResource, TermsOfUseSection } from 'src/app/shared/models/pux';
import { BrandService } from 'src/app/shared/services/brand.service';
import { TermsOfUseModalComponent } from 'src/app/terms-of-use/components/terms-of-use-modal/terms-of-use-modal.component';

import { version } from '../../../../../package.json';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContactUsModalComponent } from '@app/shared/components/contact-us-modal/contact-us-modal.component';

@Component({
  templateUrl: './login-federated-idpi.component.html',
  styleUrls: ['./login-federated-idpi.component.scss'],
})
export class LoginFederatedIdPiPageComponent implements OnInit {
  @ViewChild(TermsOfUseModalComponent) public termsOfUseModal: TermsOfUseModalComponent;
  @ViewChild(ContactUsModalComponent, { static: true }) public contactUsModal: ContactUsModalComponent;
  public readonly brand: BrandResource = this.brandService.getBrandResources();
  public currentYear: number;
  public version: string = version;
  public timer$: Observable<number>;

  public constructor(
    private authenticationService: AuthenticationService,
    private brandService: BrandService,
  ) { }

  public ngOnInit(): void {
    this.timer$ = timer(0, 1000).pipe(map((tick: number) => tick));
    this.currentYear = new Date().getFullYear();

    this.authenticationService.listenForFederatedAuthentication();
  }

  public openPrivacyPolicy(): void {
    this.termsOfUseModal.open(TermsOfUseSection.PrivacyPolicy);
  }

  public openTermsOfUse(): void {
    this.termsOfUseModal.open();
  }

  public async openContactUs(): Promise<void> {
    await this.contactUsModal.open();
  }
}
